import React from "react";
// import axios from "axios";
// import useAxios from "../../helpers/useAxios";
import {
  // TextInput,
  // Form,
  // FormField,
  // Button,
  // Box,
  Header,
  Image,
  Page,
  // PageContent,
  // Spinner,
  // Text,
  // ResponsiveContext,
} from "grommet";
// import { Insecure } from "grommet-icons";
import logo from "../../assets/logo.png";
// import background from "../../assets/gras.jpg";

const General = ({ generalAccess, setGeneralAccess }) => {
  // const initialFormValue = {
  //   access: "",
  // };
  // const [value, setValue] = React.useState(initialFormValue);
  // const [warning, setWarning] = React.useState("");
  // const { response, error, loading } = useAxios("/general");
  // const windowSize = React.useContext(ResponsiveContext);

  // if (generalAccess) return null;
  return (
    <Page
      kind="narrow"
      style={{ minHeight: "100vh", justifyContent: "center" }}
    >
      <Header direction="column">
        <Image src={logo} alt="M&T" />
      </Header>
      {/* <PageContent flex="grow">
        <Box pad="large">
          {loading ? (
            <Spinner />
          ) : (
            <Form
              value={value}
              onChange={(nextValue) => {
                setWarning("");
                setValue(nextValue);
              }}
              onSubmit={({ value }) => {
                setWarning("");
                axios({
                  url: "/access",
                  method: "POST",
                  data: value,
                })
                  .then((res) => {
                    if (res.data === false)
                      setWarning(response?.attributes?.tryAgainWarning);
                    setGeneralAccess(res.data);
                  })
                  .catch((err) => console.log(err));
              }}
            >
              <FormField name="access" htmlFor="access" label="">
                <TextInput
                  id="access"
                  name="access"
                  type="password"
                  placeholder={response?.attributes?.unlockIntroduction}
                />
              </FormField>
              <Box
                direction={windowSize === "small" ? "column" : "row"}
                align="center"
                gap="small"
              >
                <Button
                  icon={<Insecure />}
                  type="submit"
                  primary
                  label={response?.attributes?.unlockButton}
                />
                <Text textAlign="center" color="status-warning">
                  {(error && error.message) ?? warning}
                </Text>
              </Box>
            </Form>
          )}
        </Box>
      </PageContent> */}
    </Page>
  );
};

export default General;
