import { Grommet, Main } from "grommet";
import General from "../General";
// import Home from "../Home";
import theme from "./App.theme.js";
import useLocalStorage from "../../helpers/useLocalStorage";
import { Routes, Route } from "react-router-dom";

// const ConfirmUserPage = () => null;

const App = () => {
  const [generalAccess, setGeneralAccess] = useLocalStorage("access", false);
  // const [user, setUser] = useLocalStorage("user", "");

  return (
    <Grommet theme={theme}>
      <Main>
        <Routes>
          {/* <Route path="/confirmed" element={ConfirmUserPage} />
          {generalAccess && (
            <Route
              path="/"
              element={
                <Home
                  user={user}
                  setUser={setUser}
                  setGeneralAccess={setGeneralAccess}
                />
              }
            />
          )} */}
          <Route
            path="/"
            element={
              <General
                generalAccess={generalAccess}
                setGeneralAccess={setGeneralAccess}
              />
            }
          />
        </Routes>
      </Main>
    </Grommet>
  );
};

export default App;
