const theme = {
  rounding: 4,
  spacing: 16,
  global: {
    colors: {
      brand: "#FF4040",
      "neutral-1": "#b30000",
      "neutral-4": "#ffe6e6",
      "accent-1": "#900C3F",
      "accent-4": "#C70039",
    },
    focus: {
      border: {
        color: "#FF7171",
      },
    },
    font: {
      family: "Nunito",
      size: "16px",
      height: "18px",
    },
  },
  heading: {
    font: {
      family: "Frank Ruhl Libre",
    },
  },
};

export default theme;
